import { Component, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { FetchCountryList } from './ngxsstore/app';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  constructor(private store: Store, private titleService: Title) {
    this.titleService.setTitle('SK Overseas HR Consultancy');
    // this.store.dispatch([new FetchCountryList()]);
  }
}
