import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-create-pass-basic',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './create-pass-basic.component.html',
  styles: ``
})
export class CreatePassBasicComponent {

}
