import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  LUCIDE_ICONS,
  LucideAngularModule,
  LucideIconProvider,
  icons,
} from 'lucide-angular';
import { CustomInputComponent } from '../../../../Component/custom/custom-input/custom-input.component';
import { Store } from '@ngxs/store';
import { Login } from '../../../../ngxsstore/auth';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { Navigate } from '@ngxs/router-plugin';
import { ApiService } from '../../../../service/api/api.service';

@Component({
  selector: 'app-login-cover',
  standalone: true,
  imports: [
    RouterModule,
    LucideAngularModule,
    ReactiveFormsModule,
    CustomInputComponent,
  ],
  templateUrl: './login-cover.component.html',
  styles: ``,
  providers: [
    {
      provide: LUCIDE_ICONS,
      multi: true,
      useValue: new LucideIconProvider(icons),
    },
  ],
})
export class LoginCoverComponent implements OnInit {
  // @ViewChild(ToastContainerDirective, { static: true })
  // toastContainer!: ToastContainerDirective;

  loginForm: FormGroup;
  isSubmit: boolean = false;

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private toastrService: ToastrService
  ) {
    this.loginForm = this.fb.group({
      email: this.fb.control(null, [Validators.required]),
      password: this.fb.control(null, [Validators.required]),
    });
  }

  login() {
    this.isSubmit = true;
    if (this.loginForm.valid) {
      console.log(this.loginForm.value);
      this.store.dispatch([new Login(this.loginForm.value)]);
    }
  }

  ngOnInit(): void {
    // this.toastrService.overlayContainer = this.toastContainer;
  }
}
