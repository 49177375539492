export class PaymentForm {
  constructor(
    private enquiry_for: string,
    private enquiry_detail: string,
    private remark: string,
    private followup_date: Date,
    private doc: string
  ) {}
}

export class Payment extends PaymentForm {
  constructor(
    private id: string,
    enquiry_for: string,
    enquiry_detail: string,
    remark: string,
    followup_date: Date,
    doc: string
  ) {
    super(enquiry_for, enquiry_detail, remark, followup_date, doc);
  }
}
