import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EMPTY } from 'rxjs';
import { Payment } from '../model/payment';

export interface paymentstatemodel {
  list: Payment[];
  item: Payment | null;
}

export class FetchPaymentList {
  static readonly type = '[FetchPaymentList] PaymentState';
}

export class FetchPaymentById {
  static readonly type = '[FetchPaymentById] PaymentState';
  constructor(public id: string) {}
}

@State<paymentstatemodel>({
  name: 'payment',
  defaults: {
    list: [
      new Payment('1', 'sample', 'deatil', 'remarks', new Date(), '1'),
      new Payment('2', 'sample', 'deatil', 'remarks', new Date(), '1'),
      new Payment('3', 'sample', 'deatil', 'remarks', new Date(), '1'),
      new Payment('4', 'sample', 'deatil', 'remarks', new Date(), '1'),
    ],
    item: null,
  },
})
@Injectable()
export class PaymentState {
  constructor() {}

  @Selector()
  static getList(state: paymentstatemodel): any[] {
    return state.list;
  }

  @Selector()
  static getItem(state: paymentstatemodel): any {
    return state.item;
  }

  @Action(FetchPaymentList)
  fetchEnquiryList(
    states: StateContext<paymentstatemodel>,
    action: FetchPaymentList
  ) {}

  @Action(FetchPaymentById)
  fetchClientById(
    states: StateContext<paymentstatemodel>,
    action: FetchPaymentById
  ) {
    // states.patchState({
    //   item:
    // });

    return EMPTY;
  }
}
