import { NgModule, ModuleWithProviders } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { LayoutState } from './layout';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { environment } from '../../environments/environment';
import { AppState } from './app';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { JobOrderState } from './joborder';
import { CandidateState } from './candidate';
import { AssociateState } from './associate';
import { AuthState } from './auth';
import { ClientState } from './client';
import { UserState } from './user.state';
import { EnquiryState } from './enquiry';
import { PaymentState } from './payment';
import { CompanyTypeState } from './company_type';
import { IndustryState } from './industry';
import { SubIndustryState } from './sub_industry';

const states: any[] = [
  AppState,
  LayoutState,
  JobOrderState,
  CandidateState,
  AssociateState,
  AuthState,
  ClientState,
  AssociateState,
  UserState,
  EnquiryState,
  PaymentState,

  CompanyTypeState,
  IndustryState,
  SubIndustryState,
];

@NgModule({
  imports: [
    NgxsModule.forRoot([...states]),
    NgxsLoggerPluginModule.forRoot({
      collapsed: true,
      disabled: environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      key: [AuthState, CompanyTypeState, IndustryState],
    }),
    NgxsRouterPluginModule.forRoot(),
  ],
  exports: [NgxsModule],
})
export class StateModule {
  static forRoot(): ModuleWithProviders<NgxsModule> {
    return {
      ngModule: StateModule,
      providers: [],
    };
  }
}
