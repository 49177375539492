import { Component } from '@angular/core';

@Component({
  selector: 'app-create-pass-boxed',
  standalone: true,
  imports: [],
  templateUrl: './create-pass-boxed.component.html',
  styles: ``
})
export class CreatePassBoxedComponent {

}
