<body
  class="text-base bg-white text-body font-public dark:text-zink-50 dark:bg-zink-800"
>
  <nav
    class="fixed inset-x-0 top-0 z-50 flex items-center justify-center h-20 py-3 [&.is-sticky]:bg-white dark:[&.is-sticky]:bg-zink-700 border-b border-slate-200 dark:border-zink-500 [&.is-sticky]:shadow-lg [&.is-sticky]:shadow-slate-200/25 dark:[&.is-sticky]:shadow-zink-500/30 navbar"
    id="navbar"
    (window:scroll)="windoscroll()"
  >
    <div
      appScrollspy
      [spiedTags]="['SECTION']"
      (sectionChange)="onSectionChange($event)"
      class="container 2xl:max-w-[87.5rem] px-4 mx-auto flex items-center self-center w-full"
    >
      <div class="shrink-0">
        <a href="javascript:void(0);">
          <img
            src="assets/images/logo-dark.png"
            alt=""
            class="block h-6 dark:hidden"
          />
          <img
            src="assets/images/logo-light.png"
            alt=""
            class="hidden h-6 dark:block"
          />
        </a>
      </div>
      <div class="mx-auto">
        <ul
          id="navbar7"
          class="absolute inset-x-0 z-20 items-center hidden py-3 bg-white shadow-lg dark:bg-zink-600 dark:md:bg-transparent md:z-0 navbar-menu rounded-b-md md:shadow-none md:flex top-full ltr:ml-auto rtl:mr-auto md:relative md:bg-transparent md:rounded-none md:top-auto md:py-0"
        >
          <li>
            <a
              [ngxScrollTo]="'#home'"
              [ngClass]="{ active: currentSection === 'home' }"
              class="block md:inline-block px-4 md:px-3 py-2.5 md:py-0.5 text-15 font-medium text-slate-800 transition-all duration-300 ease-linear hover:text-custom-500 [&.active]:text-custom-500 dark:text-zink-100 dark:hover:text-custom-500 dark:[&.active]:text-custom-500 active"
              >Home</a
            >
          </li>
          <li>
            <a
              [ngxScrollTo]="'#features'"
              [ngClass]="{ active: currentSection === 'features' }"
              class="block md:inline-block px-4 md:px-3 py-2.5 md:py-0.5 text-15 font-medium text-slate-800 transition-all duration-300 ease-linear hover:text-custom-500 [&.active]:text-custom-500 dark:text-zink-100 dark:hover:text-custom-500 dark:[&.active]:text-custom-500"
              >Our Features</a
            >
          </li>
          <li>
            <a
              [ngxScrollTo]="'#about'"
              [ngClass]="{ active: currentSection === 'about' }"
              class="block md:inline-block px-4 md:px-3 py-2.5 md:py-0.5 text-15 font-medium text-slate-800 transition-all duration-300 ease-linear hover:text-custom-500 [&.active]:text-custom-500 dark:text-zink-100 dark:hover:text-custom-500 dark:[&.active]:text-custom-500"
              >About Us</a
            >
          </li>
          <li>
            <a
              [ngxScrollTo]="'#pricing'"
              [ngClass]="{ active: currentSection === 'pricing' }"
              class="block md:inline-block px-4 md:px-3 py-2.5 md:py-0.5 text-15 font-medium text-slate-800 transition-all duration-300 ease-linear hover:text-custom-500 [&.active]:text-custom-500 dark:text-zink-100 dark:hover:text-custom-500 dark:[&.active]:text-custom-500"
              >Pricing</a
            >
          </li>
          <li>
            <a
              [ngxScrollTo]="'#contact'"
              [ngClass]="{ active: currentSection === 'contact' }"
              class="block md:inline-block px-4 md:px-3 py-2.5 md:py-0.5 text-15 font-medium text-slate-800 transition-all duration-300 ease-linear hover:text-custom-500 [&.active]:text-custom-500 dark:text-zink-100 dark:hover:text-custom-500 dark:[&.active]:text-custom-500"
              >Contact</a
            >
          </li>
        </ul>
      </div>
      <div class="flex gap-2">
        <div class="ltr:ml-auto rtl:mr-auto md:hidden navbar-toggale-button">
          <button
            type="button"
            class="flex items-center justify-center size-[37.5px] p-0 text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
          >
            <lucide-angular name="menu"></lucide-angular>
          </button>
        </div>
        <button
          type="button"
          class="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
        >
          <span class="align-middle">Sign In </span>
          <lucide-angular
            name="log-in"
            [class]="'inline-block size-4 ltr:ml-1 rtl:mr-1'"
          ></lucide-angular>
        </button>
      </div>
    </div>
  </nav>

  <section class="relative pb-36 pt-44" id="home">
    <div
      class="absolute rotate-45 border border-dashed size-[500px] border-t-slate-300 dark:border-t-zink-500 border-l-slate-300 dark:border-l-zink-500 border-r-slate-700 dark:border-r-zink-400 border-b-slate-700 dark:border-b-zink-400 -bottom-[250px] rounded-full ltr:right-40 rtl:left-40 z-10 hidden lg:block"
    ></div>
    <div
      class="absolute rotate-45 border border-dashed size-[700px] border-t-slate-300 dark:border-t-zink-500 border-l-slate-300 dark:border-l-zink-500 border-r-slate-700 dark:border-r-zink-400 border-b-slate-700 dark:border-b-zink-400 -bottom-[350px] rounded-full ltr:right-16 rtl:left-16 z-10 hidden 2xl:block"
    ></div>
    <div class="container 2xl:max-w-[87.5rem] px-4 mx-auto">
      <div class="grid grid-cols-12 2xl:grid-cols-2">
        <div class="col-span-12 lg:col-span-7 2xl:col-span-1">
          <h1 class="mb-8 !leading-relaxed md:text-5xl">
            Effective Management of Large Projects at
            <span
              class="relative inline-block px-2 mx-2 before:block before:absolute before:-inset-1 before:-skew-y-6 before:bg-sky-50 dark:before:bg-sky-500/20 before:rounded-md before:backdrop-blur-xl"
              ><span class="relative text-sky-500">SK Overseas</span></span
            >
          </h1>
          <p class="mb-6 text-lg text-slate-500 dark:text-zink-200">
            Effective professional services project management means having a
            firm grasp on the scope, budget, resources, personnel, and timeline
            dedicated to a project. An effective project manager is able to
            manage unplanned issues while keeping the ball rolling on company
            goals and tasks in progress.
          </p>
          <div class="flex items-center gap-2">
            <button
              type="button"
              class="py-2.5 px-6 text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
            >
              <lucide-angular
                name="rocket"
                [class]="'inline-block size-4 align-middle rtl:ml-1 ltr:mr-1'"
              ></lucide-angular>
              Get Started
            </button>
            <button
              type="button"
              class="py-2.5 px-6 text-red-500 bg-white border-red-500 border-dashed btn hover:text-red-500 hover:bg-red-50 hover:border-red-600 focus:text-red-600 focus:bg-red-50 focus:border-red-600 active:text-red-600 active:bg-red-50 active:border-red-600 dark:bg-zink-800 dark:ring-red-400/20 dark:hover:bg-red-800/20 dark:focus:bg-red-800/20 dark:active:bg-red-800/20"
            >
              <lucide-angular
                name="shopping-cart"
                [class]="'inline-block size-4 align-middle rtl:ml-1 ltr:mr-1'"
              ></lucide-angular>
              <span class="align-middle"> Buy Now</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="relative py-32 bg-slate-900 dark:bg-zink-700" id="features">
    <div class="container 2xl:max-w-[87.5rem] px-4 mx-auto">
      <div class="grid grid-cols-1 gap-5 xl:grid-cols-12">
        <div class="order-2 xl:col-span-5 xl:order-1">
          <div class="relative z-10 grid grid-cols-3 gap-5 xl:grid-cols-12">
            <div class="xl:col-span-12">
              <img
                src="assets/images/landing/widgets.jpg"
                alt=""
                class="shadow-xl rtl:mr-auto ltr:ml-auto rounded-xl"
              />
            </div>
            <div class="xl:col-span-6">
              <img
                src="assets/images/landing/widgets-3.jpg"
                alt=""
                class="shadow-xl xl:mt-5 rtl:mr-auto ltr:ml-auto rounded-xl"
              />
            </div>
            <div class="xl:col-span-6">
              <img
                src="assets/images/landing/widgets-2.jpg"
                alt=""
                class="shadow-xl xl:mt-5 rtl:mr-auto ltr:ml-auto rounded-xl"
              />
            </div>
          </div>
        </div>
        <div
          class="relative z-20 order-1 xl:-mt-72 xl:col-span-7 xl:order-last"
        >
          <img
            src="assets/images/landing/home.jpg"
            alt=""
            class="shadow-2xl rounded-xl"
          />
        </div>
      </div>
      <div class="mt-24">
        <h1
          class="mb-16 leading-normal capitalize text-slate-100 dark:text-zink-50"
        >
          Build for anyone who works with SK Overseas
        </h1>
      </div>
    </div>
    <div
      class="absolute rotate-45 border border-dashed size-[500px] border-t-slate-700 border-l-slate-700 border-r-slate-700 border-b-slate-700 bottom-48 rounded-full ltr:-left-80 rtl:-right-80 hidden md:block"
    ></div>
    <div
      class="absolute rotate-45 border border-dashed size-[700px] border-t-slate-700 border-l-slate-700 border-r-slate-700 border-b-slate-700 bottom-24 rounded-full ltr:-left-96 rtl:-right-96 hidden md:block"
    ></div>
  </section>

  <section class="relative pb-32 bg-slate-50 dark:bg-zink-700/40">
    <div class="container 2xl:max-w-[87.5rem] px-4 mx-auto">
      <div
        class="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-12 gap-x-5"
      >
        <div class="xl:col-span-4">
          <div
            class="transition-all duration-300 ease-linear -mt-36 card hover:-translate-y-2 dark:bg-zink-600"
          >
            <div class="p-6">
              <img
                src="assets/images/landing/chat.jpg"
                alt=""
                class="rounded-md shadow"
              />
              <div class="mt-6">
                <span
                  class="px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-sky-100 border-sky-200 text-sky-500 dark:bg-sky-500/20 dark:border-sky-500/20"
                  >Popular Apps</span
                >
                <h6 class="mt-3 mb-2 text-lg truncate">
                  <a href="javascript:void(0);">Chat with Client & Employee</a>
                </h6>
                <p class="mb-3 text-slate-500 dark:text-zink-200 text-16">
                  A chat UI, or user interface, is one of the first things users
                  see when using a web or mobile application to communicate.
                </p>
                <a href="javascript:void(0);" class="text-custom-500 text-16"
                  >Read More
                  <lucide-angular
                    name="move-right"
                    [class]="
                      'inline-block size-4 align-middle ltr:ml-1 rtl:mr-1 rtl:rotate-180'
                    "
                  ></lucide-angular
                ></a>
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
        <div class="xl:col-span-4">
          <div
            class="transition-all duration-300 ease-linear md:-mt-36 card hover:-translate-y-2 dark:bg-zink-600"
          >
            <div class="p-6">
              <img
                src="assets/images/landing/email.jpg"
                alt=""
                class="rounded-md shadow"
              />
              <div class="mt-6">
                <span
                  class="px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-purple-100 border-purple-200 text-purple-500 dark:bg-purple-500/20 dark:border-purple-500/20"
                  >Email Apps</span
                >
                <h6 class="mt-3 mb-2 text-lg truncate">
                  <a href="javascript:void(0);"
                    >Exciting New Features Await You</a
                  >
                </h6>
                <p class="mb-3 text-slate-500 dark:text-zink-200 text-16">
                  A chat UI, or user interface, is one of the first things users
                  see when using a web or mobile application to communicate.
                </p>
                <a href="javascript:void(0);" class="text-custom-500 text-16"
                  >Read More
                  <lucide-angular
                    name="move-right"
                    [class]="
                      'inline-block size-4 align-middle ltr:ml-1 rtl:mr-1 rtl:rotate-180'
                    "
                  ></lucide-angular
                ></a>
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
        <div class="xl:col-span-4">
          <div
            class="transition-all duration-300 ease-linear xl:-mt-36 card hover:-translate-y-2 dark:bg-zink-600"
          >
            <div class="p-6">
              <img
                src="assets/images/landing/order-overview.jpg"
                alt=""
                class="rounded-md shadow"
              />
              <div class="mt-6">
                <span
                  class="px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-red-100 border-red-200 text-red-500 dark:bg-red-500/20 dark:border-red-500/20"
                  >HR Management</span
                >
                <h6 class="mt-3 mb-2 text-lg truncate">
                  <a href="javascript:void(0);"
                    >Unleash Your Creativity with Our Latest Tools</a
                  >
                </h6>
                <p class="mb-3 text-slate-500 dark:text-zink-200 text-16">
                  A chat UI, or user interface, is one of the first things users
                  see when using a web or mobile application to communicate.
                </p>
                <a href="javascript:void(0);" class="text-custom-500 text-16"
                  >Read More
                  <lucide-angular
                    name="move-right"
                    [class]="
                      'inline-block size-4 align-middle ltr:ml-1 rtl:mr-1 rtl:rotate-180'
                    "
                  ></lucide-angular
                ></a>
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end grid-->

      <div class="p-10 mt-20 rounded-md bg-slate-900 dark:bg-zink-700">
        <div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-5">
          <div class="text-center">
            <h3 class="mb-2 text-slate-50 dark:text-zink-50">
              <span class="counter-value" [countUp]="4">0</span> +
            </h3>
            <p class="text-16 text-slate-400 dark:text-zink-200">
              Multiple Layouts
            </p>
          </div>
          <!--end col-->
          <div class="text-center">
            <h3 class="mb-2 text-slate-50 dark:text-zink-50">
              <span class="counter-value" [countUp]="12">0</span> +
            </h3>
            <p class="text-16 text-slate-400 dark:text-zink-200">
              Multi Languages
            </p>
          </div>
          <!--end col-->
          <div class="text-center">
            <h3 class="mb-2 text-slate-50 dark:text-zink-50">
              <span class="counter-value" [countUp]="7">0</span> +
            </h3>
            <p class="text-16 text-slate-400 dark:text-zink-200">
              Apps & Dashboard
            </p>
          </div>
          <!--end col-->
          <div class="text-center">
            <h3 class="mb-2 text-slate-50 dark:text-zink-50">
              <span class="counter-value" [countUp]="4">0</span> +
            </h3>
            <p class="text-16 text-slate-400 dark:text-zink-200">
              Multiple Modes
            </p>
          </div>
          <!--end col-->
          <div class="text-center">
            <h3 class="mb-2 text-slate-50 dark:text-zink-50">
              <span class="counter-value" [countUp]="36500">0</span> +
            </h3>
            <p class="text-16 text-slate-400 dark:text-zink-200">
              Happy Customers
            </p>
          </div>
          <!--end col-->
        </div>
        <!--end grid-->
      </div>
    </div>
  </section>
  <!--end -->

  <section class="relative py-32" id="about">
    <div class="container 2xl:max-w-[87.5rem] px-4 mx-auto">
      <div class="mx-auto text-center xl:max-w-3xl">
        <h1 class="mb-6 leading-normal capitalize">
          Why Developers Should Embrace
          <span
            class="relative inline-block px-2 mx-2 before:block before:absolute before:-inset-1 before:-skew-y-6 before:bg-sky-50 dark:before:bg-sky-500/20 before:rounded-md before:backdrop-blur-xl"
            ><span class="relative text-sky-500">SK Overseas</span></span
          >
        </h1>
        <p class="text-lg text-slate-500 dark:text-zink-200">
          The purpose of developer communities is to provide the resources for
          developers to learn anything they want to.
        </p>
      </div>

      <div class="grid items-center grid-cols-1 gap-6 mt-20 lg:grid-cols-12">
        <div class="lg:col-span-5">
          <h1 class="mb-3 leading-normal capitalize">
            Craft a Complete Website Quickly with the Help of Design Blocks
          </h1>
          <p class="mb-6 text-lg text-slate-500 dark:text-zink-200">
            The block design approach basically breaks the design into small
            parts. These are built independently and then later combine into a
            customizable page which makes a website more flexible in terms of
            layout and content.
          </p>
          <button
            type="button"
            class="py-2.5 px-6 bg-white text-custom-500 btn border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:bg-zink-700 dark:hover:bg-custom-500 dark:ring-custom-400/20 dark:focus:bg-custom-500"
          >
            <span class="align-middle">Discover Now</span>
            <lucide-angular
              name="move-right"
              [class]="
                'inline-block size-4 align-middle ltr:ml-1 rtl:mr-1 rtl:rotate-180'
              "
            ></lucide-angular>
          </button>
        </div>
        <!--end col-->
        <div class="text-center lg:col-span-6">
          <img
            src="assets/images/landing/widgets-4.jpg"
            alt=""
            class="shadow-lg ltr:lg:ml-auto rtl:lg:mr-auto rounded-xl"
          />
          <img
            src="assets/images/landing/widgets-5.jpg"
            alt=""
            class="relative -mt-24 shadow-lg ltr:ml-auto ltr:mr-24 rtl:mr-auto rtl:ml-24 rounded-xl"
          />
        </div>
        <!--end col-->
      </div>
      <!--end grid-->

      <div class="grid items-center grid-cols-1 gap-6 mt-32 lg:grid-cols-12">
        <div class="text-center lg:col-span-6">
          <img
            src="assets/images/landing/widgets-6.jpg"
            alt=""
            class="shadow-lg ltr:mr-auto rtl:ml-auto rounded-xl"
          />
          <img
            src="assets/images/landing/widgets-7.jpg"
            alt=""
            class="relative -mt-24 shadow-lg ltr:ml-auto ltr:mr-24 rtl:mr-auto rtl:ml-24 rounded-xl"
          />
        </div>
        <!--end col-->
        <div class="lg:col-span-5">
          <h1 class="mb-3 leading-normal capitalize">
            Boost Your Business Using Our Potent Tools
          </h1>
          <p class="mb-6 text-lg text-slate-500 dark:text-zink-200">
            The block design approach basically breaks the design into small
            parts. These are built independently and then later combine into a
            customizable page which makes a website more flexible in terms of
            layout and content.
          </p>
          <button
            type="button"
            class="py-2.5 px-6 bg-white text-custom-500 btn border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:bg-zink-700 dark:hover:bg-custom-500 dark:ring-custom-400/20 dark:focus:bg-custom-500"
          >
            <span class="align-middle">Discover Now</span>
            <lucide-angular
              name="move-right"
              [class]="
                'inline-block size-4 align-middle ltr:ml-1 rtl:mr-1 rtl:rotate-180'
              "
            ></lucide-angular>
          </button>
        </div>
        <!--end col-->
      </div>
      <!--end grid-->

      <div class="grid items-center grid-cols-1 gap-5 mt-32 lg:grid-cols-12">
        <div class="lg:col-span-5">
          <h1 class="mb-3 leading-normal capitalize">
            Building a User-Friendly Website is Effortless
          </h1>
          <p class="mb-4 text-lg text-slate-500 dark:text-zink-200">
            With a user-friendly interface and easy navigation, the user
            decreases search time and increases satisfaction, fulfilling his
            needs in a fast and efficient way.
          </p>
          <ul class="flex flex-col gap-3 mb-6 text-lg list-disc list-inside">
            <li>6+ Ready touse Dashboard</li>
            <li>Light, Dark & RTL Mode Support</li>
            <li>Multiple Layouts Support</li>
          </ul>
          <button
            type="button"
            class="py-2.5 px-6 bg-white text-custom-500 btn border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:bg-zink-700 dark:hover:bg-custom-500 dark:ring-custom-400/20 dark:focus:bg-custom-500"
          >
            <span class="align-middle">Discover Now</span>
            <lucide-angular
              name="move-right"
              [class]="
                'inline-block size-4 align-middle ltr:ml-1 rtl:mr-1 rtl:rotate-180'
              "
            ></lucide-angular>
          </button>
        </div>
        <!--end col-->
        <div class="text-center lg:col-span-6">
          <img
            src="assets/images/landing/widgets-2.jpg"
            alt=""
            class="shadow-lg ltr:ml-auto rtl:mr-auto rounded-xl"
          />
          <img
            src="assets/images/landing/widgets.jpg"
            alt=""
            class="relative -mt-24 shadow-lg ltr:ml-auto rtl:mr-auto ltr:mr-24 rtl:ml-24 rounded-xl"
          />
        </div>
        <!--end col-->
      </div>
      <!--end grid-->
    </div>
    <!--end container-->
  </section>
  <!--end -->

  <section class="relative pb-32" id="pricing">
    <div class="container 2xl:max-w-[87.5rem] px-4 mx-auto">
      <div class="mx-auto text-center xl:max-w-3xl">
        <h1 class="mb-4 leading-normal capitalize">
          Tailored Website Design Package
        </h1>
        <p class="text-lg text-slate-500 dark:text-zink-200">
          A good web design package will include designing a logo, ingratiation
          with local SEO, linking a site to a social media presence, and more.
        </p>
      </div>

      <div class="grid grid-cols-1 mt-16 gap-x-5 md:grid-cols-2 xl:grid-cols-4">
        <div class="!shadow-lg text-15 card">
          <div class="card-body">
            <h5 class="mb-2">
              <lucide-angular
                name="goal"
                class="inline-block size-5 text-green-500 ltr:mr-1 rtl:ml-1"
              ></lucide-angular>
              <span class="align-middle"> Lite</span>
            </h5>
            <p class="mb-4 text-slate-500 dark:text-zink-200">
              Perfect plan for Lite
            </p>

            <h1 class="mb-4 font-normal">
              <span class="text-slate-400 dark:text-zink-200">$</span>9.99<small
                class="text-15 text-slate-500 dark:text-zink-200"
                >/Monthly</small
              >
            </h1>
            <button
              type="button"
              class="w-full bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
            >
              Purchase Now
            </button>
            <ul class="flex flex-col gap-3 mt-5">
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span><b>3</b> Projects</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span><b>299</b> Customers</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span>Scalable Bandwidth</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span><b>0</b> No Team Account</span>
              </li>
              <li
                class="flex items-center gap-2 line-through text-slate-500 dark:text-zink-200"
              >
                <lucide-angular
                  name="x"
                  [class]="'inline-block size-4 text-red-500 fill-green-100'"
                ></lucide-angular>
                <span>In app messaging</span>
              </li>
              <li
                class="flex items-center gap-2 line-through text-slate-500 dark:text-zink-200"
              >
                <lucide-angular
                  name="x"
                  [class]="'inline-block size-4 text-red-500 fill-green-100'"
                ></lucide-angular>
                <span><b>5</b> FTP Login</span>
              </li>
              <li
                class="flex items-center gap-2 line-through text-slate-500 dark:text-zink-200"
              >
                <lucide-angular
                  name="x"
                  [class]="'inline-block size-4 text-red-500 fill-green-100'"
                ></lucide-angular>
                <span>Detailed Analytics</span>
              </li>
              <li
                class="flex items-center gap-2 line-through text-slate-500 dark:text-zink-200"
              >
                <lucide-angular
                  name="x"
                  [class]="'inline-block size-4 text-red-500 fill-green-100'"
                ></lucide-angular>
                <span>Custom Domain</span>
              </li>
              <li
                class="flex items-center gap-2 line-through text-slate-500 dark:text-zink-200"
              >
                <lucide-angular
                  name="x"
                  [class]="'inline-block size-4 text-red-500 fill-green-100'"
                ></lucide-angular>
                <span>Smart Workflow</span>
              </li>
              <li
                class="flex items-center gap-2 line-through text-slate-500 dark:text-zink-200"
              >
                <lucide-angular
                  name="x"
                  [class]="'inline-block size-4 text-red-500 fill-green-100'"
                ></lucide-angular>
                <span>IQ test</span>
              </li>
            </ul>
          </div>
        </div>
        <!--end card-->
        <div class="!shadow-lg text-15 card">
          <div class="card-body">
            <h5 class="mb-2">
              <lucide-angular
                name="graduation-cap"
                [class]="'inlinesize text-purple-500 ltr:mr-1 rtl:ml-1'"
              ></lucide-angular>
              <span class="align-middle"> Professional</span>
            </h5>
            <p class="mb-4 text-slate-500 dark:text-zink-200">
              For users who want to do more.
            </p>

            <h1 class="mb-4 font-normal">
              <span class="text-slate-400 dark:text-zink-200">$</span
              >29.99<small class="text-15 text-slate-500 dark:text-zink-200"
                >/Monthly</small
              >
            </h1>
            <button
              type="button"
              class="w-full bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
            >
              Purchase Now
            </button>
            <ul class="flex flex-col gap-3 mt-5">
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span><b>3</b> Projects</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span><b>299</b> Customers</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span>Scalable Bandwidth</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span><b>3</b> No Team Account</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span>In app messaging</span>
              </li>
              <li
                class="flex items-center gap-2 line-through text-slate-500 dark:text-zink-200"
              >
                <lucide-angular
                  name="x"
                  [class]="'inline-block size-4 text-red-500 fill-green-100'"
                ></lucide-angular>
                <span><b>5</b> FTP Login</span>
              </li>
              <li
                class="flex items-center gap-2 line-through text-slate-500 dark:text-zink-200"
              >
                <lucide-angular
                  name="x"
                  [class]="'inline-block size-4 text-red-500 fill-green-100'"
                ></lucide-angular>
                <span>Detailed Analytics</span>
              </li>
              <li
                class="flex items-center gap-2 line-through text-slate-500 dark:text-zink-200"
              >
                <lucide-angular
                  name="x"
                  [class]="'inline-block size-4 text-red-500 fill-green-100'"
                ></lucide-angular>
                <span>Custom Domain</span>
              </li>
              <li
                class="flex items-center gap-2 line-through text-slate-500 dark:text-zink-200"
              >
                <lucide-angular
                  name="x"
                  [class]="'inline-block size-4 text-red-500 fill-green-100'"
                ></lucide-angular>
                <span>Smart Workflow</span>
              </li>
              <li
                class="flex items-center gap-2 line-through text-slate-500 dark:text-zink-200"
              >
                <lucide-angular
                  name="x"
                  [class]="'inline-block size-4 text-red-500 fill-green-100'"
                ></lucide-angular>
                <span>IQ test</span>
              </li>
            </ul>
          </div>
        </div>
        <!--end card-->
        <div class="relative overflow-hidden card !shadow-lg text-15">
          <div class="card-body">
            <div class="absolute top-0 size-16 ltr:right-0 rtl:left-0">
              <div
                class="absolute transform ltr:rotate-45 rtl:-rotate-45 bg-sky-500 text-center text-white font-medium py-1 ltr:-right-12 rtl:-left-12 top-6 w-[170px]"
              >
                25% Sale
              </div>
            </div>
            <h5 class="mb-2">
              <lucide-angular
                name="crown"
                class="inlinesize ltr:mr-1 rtl:ml-1 text-sky-500"
              ></lucide-angular>
              <span class="align-middle"> Enterprise</span>
            </h5>
            <p class="mb-4 text-slate-500 dark:text-zink-200">
              Run your company on your teams
            </p>

            <h1 class="mb-4 font-normal">
              <span class="text-slate-400 dark:text-zink-200">$</span
              >39.99<small class="text-15 text-slate-500 dark:text-zink-200"
                >/Monthly</small
              >
            </h1>
            <button
              type="button"
              class="w-full bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
            >
              Purchase Now
            </button>
            <ul class="flex flex-col gap-3 mt-5">
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span><b>3</b> Projects</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span><b>299</b> Customers</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span>Scalable Bandwidth</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span><b>3</b> No Team Account</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span>In app messaging</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span><b>5</b> FTP Login</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span>Detailed Analytics</span>
              </li>
              <li
                class="flex items-center gap-2 line-through text-slate-500 dark:text-zink-200"
              >
                <lucide-angular
                  name="x"
                  [class]="'inline-block size-4 text-red-500 fill-green-100'"
                ></lucide-angular>
                <span>Custom Domain</span>
              </li>
              <li
                class="flex items-center gap-2 line-through text-slate-500 dark:text-zink-200"
              >
                <lucide-angular
                  name="x"
                  [class]="'inline-block size-4 text-red-500 fill-green-100'"
                ></lucide-angular>
                <span>Smart Workflow</span>
              </li>
              <li
                class="flex items-center gap-2 line-through text-slate-500 dark:text-zink-200"
              >
                <lucide-angular
                  name="x"
                  [class]="'inline-block size-4 text-red-500 fill-green-100'"
                ></lucide-angular>
                <span>IQ test</span>
              </li>
            </ul>
          </div>
        </div>
        <!--end card-->
        <div class="!shadow-lg text-15 card">
          <div class="card-body">
            <h5 class="mb-2">
              <lucide-angular
                name="luggage"
                class="inlinesize text-orange-500 ltr:mr-1 rtl:ml-1"
              ></lucide-angular>
              <span class="align-middle"> Unlimited</span>
            </h5>
            <p class="mb-4 text-slate-500 dark:text-zink-200">
              Your entire team in one place
            </p>

            <h1 class="mb-4 font-normal">
              <span class="text-slate-400 dark:text-zink-200">$</span
              >49.99<small class="text-15 text-slate-500 dark:text-zink-200"
                >/Monthly</small
              >
            </h1>
            <button
              type="button"
              class="w-full bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
            >
              Purchase Now
            </button>
            <ul class="flex flex-col gap-3 mt-5">
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span><b>3</b> Projects</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span><b>299</b> Customers</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span>Scalable Bandwidth</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span><b>3</b> No Team Account</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span>In app messaging</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span><b>5</b> FTP Login</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span>Detailed Analytics</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span>Custom Domain</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span>Smart Workflow</span>
              </li>
              <li class="flex items-center gap-2">
                <lucide-angular
                  name="check-check"
                  [class]="
                    'inline-block size-4 text-green-500 fill-green-100 dark:fill-green-500/20'
                  "
                ></lucide-angular>
                <span>IQ test</span>
              </li>
            </ul>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end grid-->
    </div>
    <!--end container-->
  </section>

  <section class="relative py-20 bg-custom-600 dark:bg-custom-800" id="contact">
    <div
      class="absolute rotate-45 border border-dashed size-[500px] border-t-custom-500 border-l-custom-500 border-r-slate-700 border-b-slate-700 -bottom-[250px] rounded-full ltr:right-40 rtl:left-40 z-10 hidden lg:block"
    ></div>
    <div class="container 2xl:max-w-[87.5rem] px-4 mx-auto">
      <div class="grid items-center grid-cols-1 gap-5 lg:grid-cols-12">
        <div class="lg:col-span-9">
          <h1 class="mb-4 leading-normal capitalize text-custom-50">
            Ready to get started with SK Overseas
          </h1>
          <p class="text-lg text-custom-200">
            Tell us which describes you, and we'll get in touch with next steps.
          </p>
        </div>
        <div class="ltr:lg:text-right rtl:lg:text-left lg:col-span-3">
          <button
            type="button"
            class="py-2.5 px-6 text-base transition-all duration-200 ease-linear bg-white text-custom-500 btn border-white hover:text-custom-600 hover:bg-white hover:border-white z-10 relative"
          >
            <lucide-angular
              name="shopping-cart"
              [class]="'inline-block size-4 align-middle ltr:mr-1 rtl:ml-1'"
            ></lucide-angular>
            <span class="align-middle"> Purchase Now</span>
          </button>
        </div>
      </div>
    </div>
    <!--end container-->
  </section>

  <footer class="relative pt-20 pb-12 bg-slate-800 dark:bg-zink-700">
    <div class="container 2xl:max-w-[87.5rem] px-4 mx-auto">
      <div class="relative z-10 grid grid-cols-12 gap-5 xl:grid-cols-12">
        <div class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
          <h5
            class="mb-4 font-medium tracking-wider text-slate-50 dark:text-zink-50"
          >
            Dashboards
          </h5>
          <ul class="flex flex-col gap-3 text-15">
            <li>
              <a
                href="javascript:void(0);"
                class="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear"
                >Analytics</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                class="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear"
                >CRM</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                class="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear"
                >Ecommerce</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                class="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear"
                >Email</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                class="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear"
                >HR</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                class="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear"
                >Social Media</a
              >
            </li>
          </ul>
        </div>
        <!--end col-->
        <div class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
          <h5
            class="mb-4 font-medium tracking-wider text-slate-50 dark:text-zink-50"
          >
            Apps Pages
          </h5>
          <ul class="flex flex-col gap-3 text-15">
            <li>
              <a
                href="javascript:void(0);"
                class="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear"
                >Ecommerce Apps</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                class="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear"
                >Invoices</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                class="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear"
                >Email App</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                class="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear"
                >Chat App</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                class="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear"
                >Users Apps</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                class="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear"
                >HR Management</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                class="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear"
                >Social Media</a
              >
            </li>
          </ul>
        </div>
        <!--end col-->
        <div class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-2">
          <h5
            class="mb-4 font-medium tracking-wider text-slate-50 dark:text-zink-50"
          >
            Resources
          </h5>
          <ul class="flex flex-col gap-3 text-15">
            <li>
              <a
                href="javascript:void(0);"
                class="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear"
                >All Resources</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                class="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear"
                >Blog</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                class="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear"
                >FAQ's</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0);"
                class="relative inline-block transition-all duration-200 ease-linear text-slate-400 dark:text-zink-200 hover:text-slate-300 dark:hover:text-zink-50 before:absolute before:border-b before:border-slate-500 dark:before:border-zink-500 before:inset-x-0 before:bottom-0 before:w-0 hover:before:w-full before:transition-all before:duration-300 before:ease-linear"
                >Help Center</a
              >
            </li>
          </ul>
        </div>
        <!--end col-->
        <div class="col-span-12 md:col-span-6 lg:col-span-12 xl:col-span-4">
          <form class="relative mb-6">
            <input
              type="email"
              id="subscribeInput"
              class="py-3 ltr:pr-40 rtl:pl-40 bg-slate-700/60 dark:bg-zink-600/40 form-input text-slate-200 border-slate-700 dark:border-zink-500 focus:outline-none focus:border-custom-500 dark:focus:border-custom-500 placeholder:text-slate-500 dark:placeholder:text-zink-200 backdrop-blur-md"
              autocomplete="off"
              placeholder="skoverseas@themesdesign.com"
              required
            />
            <button
              type="submit"
              class="absolute px-6 py-2 text-base transition-all duration-200 ease-linear border-custom-500 bg-custom-500 ltr:right-1 rtl:left-1 text-custom-50 btn hover:text-custom-50 hover:bg-custom-600 hover:border-custom-600 top-1 bottom-1"
            >
              Subscribe Now
            </button>
          </form>

          <p class="mb-1 text-slate-500 dark:text-zink-200 text-15">
            Support Email
          </p>
          <h5 class="text-lg !font-normal text-slate-200 dark:text-zink-50">
            support&#64;themesdesign.com
          </h5>

          <p class="mt-6 mb-1 text-slate-500 dark:text-zink-200 text-15">
            Contact Us
          </p>
          <h5 class="text-lg !font-normal text-slate-200 dark:text-zink-50">
            +(012) 1202 012 4567
          </h5>
        </div>
      </div>
      <!--end grid-->

      <div class="py-5 mt-20 border-y border-slate-700">
        <div class="relative z-10 grid grid-cols-1 gap-5 md:grid-cols-12">
          <div class="md:col-span-2">
            <div class="relative dropdown" mndropdown [placement]="'top-end'">
              <button
                type="button"
                mndropdownToggle
                class="inline-flex items-center gap-3 transition-all duration-200 ease-linear dropdown-toggle btn border-slate-700 group/items focus:border-custom-500"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
              >
                <img
                  src="assets/images/flags/us.svg"
                  alt=""
                  class="object-cover h-5 rounded-full"
                />
                <h6
                  class="text-base font-medium transition-all duration-200 ease-linear text-slate-300 group-hover/items:text-custom-500"
                >
                  English
                </h6>
              </button>

              <div
                mndropdownMenu
                class="absolute z-50 p-3 mt-1 text-left list-none bg-white rounded-md shadow-md dropdown-menu min-w-[9rem] flex flex-col gap-3 dark:bg-zink-600"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  href="javascript:void(0);"
                  class="flex items-center gap-3 group/items"
                >
                  <img
                    src="assets/images/flags/us.svg"
                    alt=""
                    class="object-cover h-4 rounded-full"
                  />
                  <h6
                    class="text-sm font-medium transition-all duration-200 ease-linear text-slate-600 dark:text-zink-100 group-hover/items:text-custom-500 dark:group-hover/items:text-custom-500"
                  >
                    English
                  </h6>
                </a>
                <a
                  href="javascript:void(0);"
                  class="flex items-center gap-3 group/items"
                >
                  <img
                    src="assets/images/flags/es.svg"
                    alt=""
                    class="object-cover h-4 rounded-full"
                  />
                  <h6
                    class="text-sm font-medium transition-all duration-200 ease-linear text-slate-600 dark:text-zink-100 group-hover/items:text-custom-500 dark:group-hover/items:text-custom-500"
                  >
                    Spanish
                  </h6>
                </a>
                <a
                  href="javascript:void(0);"
                  class="flex items-center gap-3 group/items"
                >
                  <img
                    src="assets/images/flags/de.svg"
                    alt=""
                    class="object-cover h-4 rounded-full"
                  />
                  <h6
                    class="text-sm font-medium transition-all duration-200 ease-linear text-slate-600 dark:text-zink-100 group-hover/items:text-custom-500 dark:group-hover/items:text-custom-500"
                  >
                    German
                  </h6>
                </a>
                <a
                  href="javascript:void(0);"
                  class="flex items-center gap-3 group/items"
                >
                  <img
                    src="assets/images/flags/fr.svg"
                    alt=""
                    class="object-cover h-4 rounded-full"
                  />
                  <h6
                    class="text-sm font-medium transition-all duration-200 ease-linear text-slate-600 dark:text-zink-100 group-hover/items:text-custom-500 dark:group-hover/items:text-custom-500"
                  >
                    French
                  </h6>
                </a>
                <a
                  href="javascript:void(0);"
                  class="flex items-center gap-3 group/items"
                >
                  <img
                    src="assets/images/flags/jp.svg"
                    alt=""
                    class="object-cover h-4 rounded-full"
                  />
                  <h6
                    class="text-sm font-medium transition-all duration-200 ease-linear text-slate-600 dark:text-zink-100 group-hover/items:text-custom-500 dark:group-hover/items:text-custom-500"
                  >
                    Japanese
                  </h6>
                </a>
                <a
                  href="javascript:void(0);"
                  class="flex items-center gap-3 group/items"
                >
                  <img
                    src="assets/images/flags/it.svg"
                    alt=""
                    class="object-cover h-4 rounded-full"
                  />
                  <h6
                    class="text-sm font-medium transition-all duration-200 ease-linear text-slate-600 dark:text-zink-100 group-hover/items:text-custom-500 dark:group-hover/items:text-custom-500"
                  >
                    Italian
                  </h6>
                </a>
                <a
                  href="javascript:void(0);"
                  class="flex items-center gap-3 group/items"
                >
                  <img
                    src="assets/images/flags/ru.svg"
                    alt=""
                    class="object-cover h-4 rounded-full"
                  />
                  <h6
                    class="text-sm font-medium transition-all duration-200 ease-linear text-slate-600 dark:text-zink-100 group-hover/items:text-custom-500 dark:group-hover/items:text-custom-500"
                  >
                    Russian
                  </h6>
                </a>
                <a
                  href="javascript:void(0);"
                  class="flex items-center gap-3 group/items"
                >
                  <img
                    src="assets/images/flags/ae.svg"
                    alt=""
                    class="object-cover h-4 rounded-full"
                  />
                  <h6
                    class="text-sm font-medium transition-all duration-200 ease-linear text-slate-600 dark:text-zink-100 group-hover/items:text-custom-500 dark:group-hover/items:text-custom-500"
                  >
                    Arabic
                  </h6>
                </a>
              </div>
            </div>
          </div>
          <div class="md:col-span-3 md:col-start-10">
            <ul class="flex items-center gap-3 md:justify-end">
              <li>
                <a
                  href="javascript:void(0);"
                  class="flex items-center justify-center size-10 transition-all duration-200 ease-linear border rounded-full text-slate-400 border-slate-700 hover:text-custom-500"
                  ><lucide-angular
                    name="facebook"
                    [class]="'size-4'"
                  ></lucide-angular
                ></a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  class="flex items-center justify-center size-10 transition-all duration-200 ease-linear border rounded-full text-slate-400 border-slate-700 hover:text-custom-500"
                  ><lucide-angular
                    name="linkedin"
                    [class]="'size-4'"
                  ></lucide-angular
                ></a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  class="flex items-center justify-center size-10 transition-all duration-200 ease-linear border rounded-full text-slate-400 border-slate-700 hover:text-custom-500"
                  ><lucide-angular
                    name="instagram"
                    [class]="'size-4'"
                  ></lucide-angular
                ></a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  class="flex items-center justify-center size-10 transition-all duration-200 ease-linear border rounded-full text-slate-400 border-slate-700 hover:text-custom-500"
                  ><lucide-angular
                    name="twitter"
                    [class]="'size-4'"
                  ></lucide-angular
                ></a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  class="flex items-center justify-center size-10 transition-all duration-200 ease-linear border rounded-full text-slate-400 border-slate-700 hover:text-custom-500"
                  ><lucide-angular
                    name="youtube"
                    [class]="'size-4'"
                  ></lucide-angular
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="mt-12 text-center text-slate-400 dark:text-zink-200 text-16">
        <p>
          {{ year }} © SK Overseas. Design & Develop by
          <a
            href="javascript:void(0);"
            class="underline text-slate-300 dark:text-zink-100"
            >Themesdesign</a
          >
        </p>
      </div>
    </div>
  </footer>

  <button
    id="back-to-top"
    (click)="topFunction()"
    class="fixed flex items-center justify-center size-10 text-white bg-purple-500 rounded-md bottom-10 right-10"
  >
    <lucide-angular
      name="chevron-up"
      [class]="'animate animate-icons'"
    ></lucide-angular>
  </button>
</body>
