<body
  class="flex items-center justify-center min-h-screen py-16 bg-cover bg-auth-pattern dark:bg-auth-pattern-dark dark:text-zink-100 font-public"
>
  <div
    class="mb-0 border-none lg:w-[500px] card bg-white/70 shadow-none dark:bg-zink-500/70"
  >
    <div class="!px-10 !py-12 card-body">
      <a href="index">
        <img
          src="assets/images/logo-light.png"
          alt=""
          class="hidden h-6 mx-auto dark:block"
        />
        <img
          src="assets/images/logo-dark.png"
          alt=""
          class="block h-6 mx-auto dark:hidden"
        />
      </a>

      <div class="mt-8 text-center">
        <h4 class="mb-1 text-custom-500 dark:text-custom-500">
          Create your free account
        </h4>
        <p class="text-slate-500 dark:text-zink-200">
          Get your free Sk account now
        </p>
      </div>

      <form [formGroup]="registerForm" class="mt-10" (ngSubmit)="register()">
        <div class="mb-3">
          <app-custom-input
            formControlName="name"
            label="User Name"
            [required]="true"
            [isSubmitted]="isSubmit"
          >
          </app-custom-input>
        </div>
        <div class="mb-3">
          <app-custom-input
            formControlName="mobileno"
            label="Mobile Number"
            [required]="true"
            [isSubmitted]="isSubmit"
          >
          </app-custom-input>
        </div>
        <div class="mb-3">
          <app-custom-input
            formControlName="email"
            label="Email"
            [required]="true"
            [isSubmitted]="isSubmit"
          >
          </app-custom-input>
        </div>
        <div class="mb-3">
          <app-custom-input
            formControlName="password"
            label="Password"
            [required]="true"
            [isSubmitted]="isSubmit"
            type="password"
          >
          </app-custom-input>
        </div>
        <p class="italic text-15 text-slate-500 dark:text-zink-200">
          By registering you agree to the SK Overseas
          <a (click)="termsCondition()" class="underline">Terms of Use</a>
        </p>
        <div class="mt-10">
          <button
            type="submit"
            class="w-full text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
          >
            Register
          </button>
        </div>

        <!-- <div class="relative text-center my-9 before:absolute before:top-3 before:left-0 before:right-0 before:border-t before:border-t-slate-200 dark:before:border-t-zink-500">
                    <h5 class="inline-block px-2 py-0.5 text-sm bg-white text-slate-500 dark:bg-zink-600 dark:text-zink-200 rounded relative">Create account with</h5>
                </div>

                <div class="flex flex-wrap justify-center gap-2">
                    <button type="button" class="flex items-center justify-center size-[37.5px] transition-all duration-200 ease-linear p-0 text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 active:text-white active:bg-custom-600 active:border-custom-600"><lucide-angular name="facebook" [class]="'size-4'"></lucide-angular></button>
                    <button type="button" class="flex items-center justify-center size-[37.5px] transition-all duration-200 ease-linear p-0 text-white btn bg-orange-500 border-orange-500 hover:text-white hover:bg-orange-600 hover:border-orange-600 focus:text-white focus:bg-orange-600 focus:border-orange-600 active:text-white active:bg-orange-600 active:border-orange-600"><lucide-angular name="mail" [class]="'size-4'"></lucide-angular></button>
                    <button type="button" class="flex items-center justify-center size-[37.5px] transition-all duration-200 ease-linear p-0 text-white btn bg-sky-500 border-sky-500 hover:text-white hover:bg-sky-600 hover:border-sky-600 focus:text-white focus:bg-sky-600 focus:border-sky-600 active:text-white active:bg-sky-600 active:border-sky-600"><lucide-angular name="twitter" [class]="'size-4'"></lucide-angular></button>
                    <button type="button" class="flex items-center justify-center size-[37.5px] transition-all duration-200 ease-linear p-0 text-white btn bg-slate-500 border-slate-500 hover:text-white hover:bg-slate-600 hover:border-slate-600 focus:text-white focus:bg-slate-600 focus:border-slate-600 active:text-white active:bg-slate-600 active:border-slate-600"><lucide-angular name="github" [class]="'size-4'"></lucide-angular></button>
                </div> -->

        <div class="mt-10 text-center">
          <p class="mb-0 text-slate-500 dark:text-zink-200">
            Already have an account ?
            <a
              routerLink="/auth-login-cover"
              class="font-semibold underline transition-all duration-150 ease-linear text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500"
              >Login</a
            >
          </p>
        </div>
      </form>
    </div>
  </div>
</body>
