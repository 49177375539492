import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-twostep-basic',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './twostep-basic.component.html',
  styles: ``
})
export class TwostepBasicComponent {

}
