import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-reset-pass-basic',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './reset-pass-basic.component.html',
  styles: ``
})
export class ResetPassBasicComponent {

}
