import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CutomDropdownComponent } from '../../../../Component/customdropdown';


@Component({
  selector: 'app-reset-pass-boxed',
  standalone: true,
  imports: [RouterModule, CutomDropdownComponent],
  templateUrl: './reset-pass-boxed.component.html',
  styles: ``
})
export class ResetPassBoxedComponent {

}
