import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-twostep-boxed',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './twostep-boxed.component.html',
  styles: ``
})
export class TwostepBoxedComponent {

}
