<!-- <div class="relative">
  <div
    class="absolute top-2 right-72 me-3"
    aria-live="polite"
    toastContainer
  ></div>
</div> -->

<body
  class="flex items-center justify-center min-h-screen py-16 bg-cover bg-auth-pattern dark:bg-auth-pattern-dark dark:text-zink-100 font-public">
  <div class="mb-0 border-none lg:w-[500px] card bg-white/70 shadow-none dark:bg-zink-500/70">
    <div class="!px-10 !py-12 card-body">
      <a href="index">
        <img src="assets/images/logo-light.png" alt="" class="hidden h-6 mx-auto dark:block" />
        <img src="assets/images/logo-dark.png" alt="" class="block h-6 mx-auto dark:hidden" />
      </a>

      <div class="mt-8 text-center">
        <p class="text-slate-500 dark:text-zink-200">
          Sign in to continue to Sk Overseas.
        </p>
      </div>

      <form [formGroup]="loginForm" class="mt-10" (ngSubmit)="login()">
        <div class="mb-3">
          <app-custom-input formControlName="email" label="E-mail" [required]="true" [isSubmitted]="isSubmit">
          </app-custom-input>
        </div>
        <div class="mb-3">
          <app-custom-input formControlName="password" label="Password" [required]="true" [isSubmitted]="isSubmit"
            type="password">
          </app-custom-input>
        </div>

        <div class="mt-10">
          <button type="submit"
            class="w-full text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
            Sign In
          </button>
        </div>

        <div class="mt-10 text-center">
          <p class="mb-0 text-slate-500 dark:text-zink-200">
            Don't have an account ?
            <a routerLink="/auth-register-cover"
              class="font-semibold underline transition-all duration-150 ease-linear text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500">
              SignUp</a>
          </p>
        </div>
      </form>
    </div>
  </div>
</body>