import { Component, inject } from '@angular/core';
import { DrawerModule } from '../../Component/drawer';
import { CommonModule } from '@angular/common';
import {
  LUCIDE_ICONS,
  LucideAngularModule,
  LucideIconProvider,
  icons,
} from 'lucide-angular';
import { Store } from '@ngxs/store';
import {
  ChangeDirection,
  ChangeLayout,
  ChangeMode,
  ChangeNavigation,
  ChangeSideBarSize,
  ChangeSidebarColor,
  ChangeSkin,
  ChangeTopbarColor,
  ChangeWidth,
  LayoutState,
} from '../../ngxsstore/layout';

@Component({
  selector: 'app-customizer',
  standalone: true,
  imports: [DrawerModule, CommonModule, LucideAngularModule],
  templateUrl: './customizer.component.html',
  styles: ``,
  providers: [
    {
      provide: LUCIDE_ICONS,
      multi: true,
      useValue: new LucideIconProvider(icons),
    },
  ],
})
export class CustomizerComponent {
  theme: string;
  mode: string;
  dir: string;
  skin: string;
  width: string;
  size: string;
  navbar: string;
  color: string;
  topbar: string;

  attribute: any;

  constructor(private store: Store) {
    let data = this.store.selectSnapshot(LayoutState.getLayout);
    this.theme = data.LAYOUT;
    this.skin = data.LAYOUT_SKIN;
    this.mode = data.LAYOUT_MODE;
    this.dir = data.LAYOUT_DIRECTION;
    this.width = data.LAYOUT_WIDTH;
    this.size = data.SIDEBAR_SIZE;
    this.navbar = data.LAYOUT_NAVIGATION;
    this.color = data.SIDEBAR_COLOR;
    this.topbar = data.TOPBAR_COLOR;
  }

  ngOnInit(): void {
    this.attribute = '';
  }

  // Theme Change
  changeTheme(layout: string) {
    this.theme = layout;
    this.store.dispatch(new ChangeLayout(layout));
    this.store.select(LayoutState.getLayoutType).subscribe((layout) => {
      document.documentElement.setAttribute('data-layout', layout);
    });

    if (layout == 'horizontal') {
      this.store.dispatch(new ChangeSideBarSize('lg'));
      this.store.select(LayoutState.getSidebarsize).subscribe((size) => {
        document.documentElement.setAttribute('data-sidebar-size', size);
      });
    }
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }

  // Semi Layout Change
  changeSemiLayout(event: any) {
    if (event.target.checked == true) {
      this.store.dispatch(new ChangeTopbarColor('dark'));
      this.store.dispatch(new ChangeSidebarColor('dark'));
      this.store.select(LayoutState.getTopbarcolor).subscribe((topbar) => {
        document.documentElement.setAttribute('data-topbar', topbar);
        document.documentElement.setAttribute('data-sidebar', topbar);
      });
    } else {
      this.store.dispatch(new ChangeTopbarColor('light'));
      this.store.dispatch(new ChangeSidebarColor('light'));
      this.store.select(LayoutState.getTopbarcolor).subscribe((topbar) => {
        document.documentElement.setAttribute('data-topbar', topbar);
        document.documentElement.setAttribute('data-sidebar', topbar);
      });
    }
  }

  // skin Change
  changeSkinLayout(skin: string) {
    this.skin = skin;
    this.store.dispatch(new ChangeSkin(skin));
    this.store.select(LayoutState.getLayoutSkin).subscribe((skin) => {
      document.documentElement.setAttribute('data-skin', skin);
    });
  }

  // Mode Change
  changeLayoutMode(mode: string) {
    this.mode = mode;
    const topbar = mode;
    const sidebar = mode;
    this.store.dispatch(new ChangeMode(mode));
    this.store.dispatch(new ChangeTopbarColor(mode));
    this.store.dispatch(new ChangeSidebarColor(mode));
    this.store.select(LayoutState.getLayoutmode).subscribe((mode) => {
      document.documentElement.setAttribute('data-mode', mode);
      document.documentElement.setAttribute('data-topbar', mode);
      document.documentElement.setAttribute('data-sidebar', mode);
    });
  }

  // Direction Change
  changeLayoutDirection(dir: string) {
    this.dir = dir;
    this.store.dispatch(new ChangeDirection(dir));
    this.store.select(LayoutState.getLayoutdirection).subscribe((dir) => {
      document.documentElement.setAttribute('dir', dir);
    });
  }

  // Layout Width Change
  changeWidth(width: string) {
    this.width = width;
    this.store.dispatch(new ChangeWidth(width));
    this.store.select(LayoutState.getLayoutWidth).subscribe((width) => {
      document.documentElement.setAttribute('data-content', width);
    });

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }

  // sidebar size Change
  changeSize(size: string) {
    this.size = size;
    this.store.dispatch(new ChangeSideBarSize(size));
    this.store.select(LayoutState.getSidebarsize).subscribe((size) => {
      document.documentElement.setAttribute('data-sidebar-size', size);
    });
  }

  // Navigation Change
  changenavbar(navigation: string) {
    this.navbar = navigation;
    this.store.dispatch(new ChangeNavigation(navigation));
    this.store.select(LayoutState.getNavigation).subscribe((navigation) => {
      document.documentElement.setAttribute('data-navbar', navigation);
    });
  }

  // sidebar color Change
  changecolor(sidebar: string) {
    this.color = sidebar;
    this.store.dispatch(new ChangeSidebarColor(sidebar));
    this.store.select(LayoutState.getSidebarcolor).subscribe((sidebar) => {
      document.documentElement.setAttribute('data-sidebar', sidebar);
    });
  }

  // topbar color Change
  changetopbar(topbar: string) {
    this.topbar = topbar;
    this.store.dispatch(new ChangeTopbarColor(topbar));
    this.store.select(LayoutState.getTopbarcolor).subscribe((topbar) => {
      document.documentElement.setAttribute('data-topbar', topbar);
    });
  }
}
