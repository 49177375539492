import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState, IsTokenExpired } from '../../ngxsstore/auth';
import { concatMap, distinctUntilChanged, of, switchMap, tap } from 'rxjs';
import { Navigate } from '@ngxs/router-plugin';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private store: Store) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(AuthState.getToken).pipe(
      distinctUntilChanged(),
      switchMap((res) => {
        if (res !== '') {
          return this.store.dispatch(new IsTokenExpired()).pipe(
            concatMap((res) => {
              const isExpired = this.store.selectSnapshot(
                AuthState.isTokenExpired
              );
              if (isExpired) {
                this.store.dispatch([new Navigate(['/', 'auth-login-cover'])]);
                return of(false);
              } else {
                return of(true);
              }
            })
          );
        } else {
          this.store.dispatch([new Navigate(['/', 'auth-login-cover'])]);
          return of(false);
        }
      })
    );
  }
}
