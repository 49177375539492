<header
  id="page-topbar"
  (window:scroll)="windowScroll()"
  class="ltr:md:left-vertical-menu rtl:md:right-vertical-menu group-data-[sidebar-size=md]:ltr:md:left-vertical-menu-md group-data-[sidebar-size=md]:rtl:md:right-vertical-menu-md group-data-[sidebar-size=sm]:ltr:md:left-vertical-menu-sm group-data-[sidebar-size=sm]:rtl:md:right-vertical-menu-sm group-data-[layout=horizontal]:ltr:left-0 group-data-[layout=horizontal]:rtl:right-0 fixed right-0 z-[1000] left-0 print:hidden group-data-[navbar=bordered]:m-4 group-data-[navbar=bordered]:[&.is-sticky]:mt-0 transition-all ease-linear duration-300 group-data-[navbar=hidden]:hidden group-data-[navbar=scroll]:absolute group/topbar group-data-[layout=horizontal]:z-[1004]"
>
  <div class="layout-width">
    <div
      class="flex items-center px-4 mx-auto bg-topbar border-b-2 border-topbar group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:border-topbar-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:border-topbar-brand shadow-md h-header shadow-slate-200/50 group-data-[navbar=bordered]:rounded-md group-data-[navbar=bordered]:group-[.is-sticky]/topbar:rounded-t-none group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:border-zink-700 dark:shadow-none group-data-[topbar=dark]:group-[.is-sticky]/topbar:dark:shadow-zink-500 group-data-[topbar=dark]:group-[.is-sticky]/topbar:dark:shadow-md group-data-[navbar=bordered]:shadow-none group-data-[layout=horizontal]:group-data-[navbar=bordered]:rounded-b-none group-data-[layout=horizontal]:shadow-none group-data-[layout=horizontal]:dark:group-[.is-sticky]/topbar:shadow-none"
    >
      <div
        class="flex items-center w-full group-data-[layout=horizontal]:mx-auto group-data-[layout=horizontal]:max-w-screen-2xl navbar-header group-data-[layout=horizontal]:ltr:xl:pr-3 group-data-[layout=horizontal]:rtl:xl:pl-3"
      >
        <!-- LOGO -->
        <div
          class="items-center justify-center hidden px-5 text-center h-header group-data-[layout=horizontal]:md:flex group-data-[layout=horizontal]:ltr::pl-0 group-data-[layout=horizontal]:rtl:pr-0"
        >
          <a routerLink="/">
            <span class="hidden">
              <img src="assets/images/logo-sm.png" alt="" class="h-6 mx-auto" />
            </span>
            <span
              class="group-data-[topbar=dark]:hidden group-data-[topbar=brand]:hidden"
            >
              <img
                src="assets/images/logo-dark.png"
                alt=""
                class="h-6 mx-auto"
              />
            </span>
          </a>
          <a
            routerLink="/"
            class="hidden group-data-[topbar=dark]:block group-data-[topbar=brand]:block"
          >
            <span
              class="group-data-[topbar=dark]:hidden group-data-[topbar=brand]:hidden"
            >
              <img src="assets/images/logo-sm.png" alt="" class="h-6 mx-auto" />
            </span>
            <span
              class="group-data-[topbar=dark]:block group-data-[topbar=brand]:block"
            >
              <img
                src="assets/images/logo-light.png"
                alt=""
                class="h-6 mx-auto"
              />
            </span>
          </a>
        </div>

        <button
          type="button"
          (click)="changeSidebar()"
          class="inline-flex relative justify-center items-center p-0 text-topbar-item transition-all size-[37.5px] duration-75 ease-linear bg-topbar rounded-md btn hover:bg-slate-100 group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:border-topbar-dark group-data-[topbar=dark]:text-topbar-item-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:border-topbar-brand group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:text-zink-200 group-data-[topbar=dark]:dark:border-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[layout=horizontal]:flex group-data-[layout=horizontal]:md:hidden hamburger-icon"
          id="topnav-hamburger-icon"
        >
          <lucide-angular
            name="chevrons-left"
            [class]="'w-5 h-5 group-data-[sidebar-size=sm]:hidden'"
          ></lucide-angular>
          <lucide-angular
            name="chevrons-right"
            [class]="'hidden w-5 h-5 group-data-[sidebar-size=sm]:block'"
          ></lucide-angular>
        </button>

        <!-- <div class="relative hidden ltr:ml-3 rtl:mr-3 lg:block group-data-[layout=horizontal]:hidden group-data-[layout=horizontal]:lg:block">
                    <input type="text" class="py-2 pr-4 text-sm text-topbar-item bg-topbar border border-topbar-border rounded pl-8 placeholder:text-slate-400 form-control focus-visible:outline-0 min-w-[300px] focus:border-blue-400 group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:border-topbar-border-dark group-data-[topbar=dark]:placeholder:text-slate-500 group-data-[topbar=dark]:text-topbar-item-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:border-topbar-border-brand group-data-[topbar=brand]:placeholder:text-blue-300 group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:border-zink-500 group-data-[topbar=dark]:dark:text-zink-100" placeholder="Search for ..." autocomplete="off">

                    <lucide-angular name="search" [class]="'size-4 absolute left-2.5 top-2.5 text-topbar-item fill-slate-100 group-data-[topbar=dark]:fill-topbar-item-bg-hover-dark group-data-[topbar=dark]:text-topbar-item-dark group-data-[topbar=brand]:fill-topbar-item-bg-hover-brand group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:text-zink-200 group-data-[topbar=dark]:dark:fill-zink-600'"></lucide-angular>

                </div> -->

        <div class="flex gap-3 ms-auto">
          <!-- <div mndropdown [placement]="'bottom-start'" class="relative flex items-center dropdown h-header">

                        <button mndropdownToggle type="button" class="inline-flex justify-center items-center p-0 text-topbar-item transition-all size-[37.5px] duration-200 ease-linear bg-topbar rounded-md dropdown-toggle btn hover:bg-topbar-item-bg-hover hover:text-topbar-item-hover group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=dark]:dark:text-zink-500 group-data-[topbar=dark]:dark:hover:text-zink-50" id="flagsDropdown" data-bs-toggle="dropdown">
                            <img src="{{flagvalue}}" alt="" id="header-lang-img" class="h-5 rounded-sm">
                        </button>
                        <div mndropdownMenu class="absolute z-50 p-4 ltr:text-left rtl:text-right bg-white rounded-md shadow-md !top-4 dropdown-menu min-w-[10rem] flex flex-col gap-4 dark:bg-zink-600" aria-labelledby="flagsDropdown">
                            @for(item of listLang;track $index){
                            <a href="javascript:void(0);" class="flex items-center gap-3 group/items language" data-lang="en" title="English" (click)="setLanguage(item.text, item.lang, item.flag)">
                                <img src="{{item.flag}}" alt="" class="object-cover h-4 rounded-full">
                                <h6 class="transition-all duration-200 ease-linear font-15medium text- text-slate-600 dark:text-zink-200 group-hover/items:text-custom-500">{{item.text}}</h6>
                            </a>
                            }
                        </div>
                    </div> -->

          <!-- <div class="relative flex items-center h-header">
            <button
              type="button"
              (click)="changeLayoutMode()"
              class="inline-flex relative justify-center items-center p-0 text-topbar-item transition-all size-[37.5px] duration-200 ease-linear bg-topbar rounded-md btn hover:bg-topbar-item-bg-hover hover:text-topbar-item-hover group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[topbar=dark]:dark:text-zink-200 group-data-[topbar=dark]:text-topbar-item-dark"
              id="light-dark-mode"
            >
              <lucide-angular
                name="sun"
                [class]="
                  'inline-block size-5 stroke-1 fill-slate-100 group-data-[topbar=dark]:fill-topbar-item-bg-hover-dark group-data-[topbar=brand]:fill-topbar-item-bg-hover-brand'
                "
              ></lucide-angular>
            </button>
          </div> -->

          <!-- <div class="relative flex items-center h-header">
                        <button type="button" [DrawerTrigger]="'cartSidePenal'" class="inline-flex relative justify-center items-center p-0 text-topbar-item transition-all size-[37.5px] duration-200 ease-linear bg-topbar rounded-md btn hover:bg-topbar-item-bg-hover hover:text-topbar-item-hover group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[topbar=dark]:dark:text-zink-200 group-data-[topbar=dark]:text-topbar-item-dark">
                            <lucide-angular name="shopping-cart" [class]="'inline-block size-5 stroke-1 fill-slate-100 group-data-[topbar=dark]:fill-topbar-item-bg-hover-dark group-data-[topbar=brand]:fill-topbar-item-bg-hover-brand'"></lucide-angular>
                            <span class="absolute flex items-center justify-center w-[16px] h-[16px] text-xs text-white bg-red-400 border-white rounded-full -top-1 -right-1">3</span>
                        </button>
                    </div>

                    <div mndropdown [placement]="'bottom-start'" class="relative flex items-center dropdown h-header">

                        <button mndropdownToggle type="button" class="inline-flex justify-center relative items-center p-0 text-topbar-item transition-all size-[37.5px] duration-200 ease-linear bg-topbar rounded-md dropdown-toggle btn hover:bg-topbar-item-bg-hover hover:text-topbar-item-hover group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[topbar=dark]:dark:text-zink-200 group-data-[topbar=dark]:text-topbar-item-dark" id="notificationDropdown" data-bs-toggle="dropdown">
                            <lucide-angular name="bell-ring" [class]="'inline-block size-5 stroke-1 fill-slate-100 group-data-[topbar=dark]:fill-topbar-item-bg-hover-dark group-data-[topbar=brand]:fill-topbar-item-bg-hover-brand'"></lucide-angular>
                            <span class="absolute top-0 right-0 flex size-1.5">
                                <span class="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-sky-400"></span>
                                <span class="relative inline-flex size-1.5 rounded-full bg-sky-500"></span>
                            </span>
                        </button>
                        <div mndropdownMenu class="absolute z-50 ltr:text-left rtl:text-right bg-white rounded-md shadow-md !top-4 dropdown-menu min-w-[20rem] lg:min-w-[26rem] dark:bg-zink-600" aria-labelledby="notificationDropdown">
                            <div class="p-4">
                                <h6 class="mb-4 text-16">Notifications <span class="inline-flex items-center justify-center size-5 ml-1 text-[11px] font-medium border rounded-full text-white bg-orange-500 border-orange-500">{{notifyList?.length}}</span></h6>
                                <ul class="flex flex-wrap w-full p-1 mb-2 text-sm font-medium text-center rounded-md filter-btns text-slate-500 bg-slate-100 nav-tabs dark:bg-zink-500 dark:text-zink-200">
                                    <li class="group grow" (click)="NotifyFilter('all')" [ngClass]="{'active':type == 'all'}">
                                        <a href="javascript:void(0);" data-filter="all" class="inline-block nav-link px-1.5 w-full py-1 text-xs transition-all duration-300 ease-linear rounded-md text-slate-500 border border-transparent group-[.active]:bg-white group-[.active]:text-custom-500 hover:text-custom-500 active:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:group-[.active]:bg-zink-600 -mb-[1px]">View All</a>
                                    </li>
                                    <li class="group grow" (click)="NotifyFilter('mention')" [ngClass]="{'active':type == 'mention'}">
                                        <a href="javascript:void(0);" data-filter="mention" class="inline-block nav-link px-1.5 w-full py-1 text-xs transition-all duration-300 ease-linear rounded-md text-slate-500 border border-transparent group-[.active]:bg-white group-[.active]:text-custom-500 hover:text-custom-500 active:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:group-[.active]:bg-zink-600 -mb-[1px]">Mentions</a>
                                    </li>
                                    <li class="group grow" (click)="NotifyFilter('follower')" [ngClass]="{'active':type == 'follower'}">
                                        <a href="javascript:void(0);" data-filter="follower" class="inline-block nav-link px-1.5 w-full py-1 text-xs transition-all duration-300 ease-linear rounded-md text-slate-500 border border-transparent group-[.active]:bg-white group-[.active]:text-custom-500 hover:text-custom-500 active:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:group-[.active]:bg-zink-600 -mb-[1px]">Followers</a>
                                    </li>
                                    <li class="group grow" (click)="NotifyFilter('invite')" [ngClass]="{'active':type == 'invite'}">
                                        <a href="javascript:void(0);" data-filter="invite" class="inline-block nav-link px-1.5 w-full py-1 text-xs transition-all duration-300 ease-linear rounded-md text-slate-500 border border-transparent group-[.active]:bg-white group-[.active]:text-custom-500 hover:text-custom-500 active:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:group-[.active]:bg-zink-600 -mb-[1px]">Invites</a>
                                    </li>
                                </ul>

                            </div>
                            <ngx-simplebar class="max-h-[350px]">

                                <div class="flex flex-col gap-1">
                                    @for(data of notifyList;track $index){
                                    <a href="javascript:void(0);" class="flex gap-3 p-4 product-item hover:bg-slate-50 dark:hover:bg-zink-500 follower">
                                        @if(data.imageClassName){
                                        <div [ngClass]="data.imageClassName">
                                            @if(data.image){
                                            <img src="{{data.image}}" alt="" class="rounded-md">
                                            }@else {
                                            <lucide-angular name="shopping-bag" class="size-5 text-red-500 fill-red-200"></lucide-angular>
                                            }
                                        </div>
                                        }@else{
                                        <div class="relative shrink-0">
                                            <div class="size-10 bg-pink-100 rounded-md">
                                                <img src="assets/images/users/avatar-7.png" alt="" class="rounded-md">
                                            </div>
                                            <div class="absolute text-orange-500 -bottom-0.5 -right-0.5 text-16">
                                                <i class="ri-heart-fill"></i>
                                            </div>
                                        </div>
                                        }
                                        <div class="grow">
                                            <h6 class="mb-1 font-medium"><b>{{data.boldName}}</b> {{data.name}} <span class="text-red-500">{{data.price}}</span></h6>
                                            <p class="text-sm text-slate-500 dark:text-zink-300" [ngClass]="data.description?'mb-3':'mb-0'"><lucide-angular name="clock" class="inline-block size-3.5 mr-1"></lucide-angular> <span class="align-middle"> {{data.date}}</span></p>
                                            @if(data.description){<div class="p-2 rounded bg-slate-100 text-slate-500 dark:bg-zink-500 dark:text-zink-300">{{data.description}}</div>}
                                        </div>
                                        <div class="flex items-center self-start gap-2 text-xs text-slate-500 shrink-0 dark:text-zink-300">
                                            <div class="size-1.5 bg-custom-500 rounded-full"></div> {{data.time}}
                                        </div>
                                    </a>
                                    }
                                </div>

                            </ngx-simplebar>
                            <div class="flex items-center gap-2 p-4 border-t border-slate-200 dark:border-zink-500">
                                <div class="grow">
                                    <a href="javascript:void(0);">Manage Notification</a>
                                </div>
                                <div class="shrink-0">
                                    <button type="button" class="px-2 py-1.5 text-xs text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100">View All Notification <lucide-angular name="move-right" [class]="'inline-block size-3.5 ml-1'"></lucide-angular></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="relative items-center hidden h-header md:flex">
                        <button [DrawerTrigger]="'customizerButton'" type="button" class="inline-flex justify-center items-center p-0 text-topbar-item transition-all size-[37.5px] duration-200 ease-linear bg-topbar group-data-[topbar=dark]:text-topbar-item-dark rounded-md btn hover:bg-topbar-item-bg-hover hover:text-topbar-item-hover group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[topbar=dark]:dark:text-zink-200">
                            <lucide-angular name="settings" [class]="'inline-block size-5 stroke-1 fill-slate-100 group-data-[topbar=dark]:fill-topbar-item-bg-hover-dark group-data-[topbar=brand]:fill-topbar-item-bg-hover-brand'"></lucide-angular>
                        </button>
                    </div> -->

          <div
            mndropdown
            [placement]="'top-end'"
            class="relative flex items-center dropdown h-header"
          >
            <button
              mndropdownToggle
              type="button"
              class="inline-block p-0 transition-all duration-200 ease-linear bg-topbar rounded-full text-topbar-item dropdown-toggle btn hover:bg-topbar-item-bg-hover hover:text-topbar-item-hover group-data-[topbar=dark]:text-topbar-item-dark group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[topbar=dark]:dark:text-zink-200"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
            >
              <div class="bg-pink-100 rounded-full">
                <img
                  src="assets/images/users/user-profile.png"
                  alt=""
                  class="size-[37.5px] rounded-full"
                />
              </div>
            </button>
            <div
              mndropdownMenu
              class="z-50 p-4 ltr:text-left rtl:text-right bg-white rounded-md shadow-md !top-4 dropdown-menu min-w-[14rem] dark:bg-zink-600"
              aria-labelledby="dropdownMenuButton"
              style="
                position: absolute;
                transform: translate3d(-0.5px, 64.5px, 0px);
                display: flex;
                inset: 0px 0px auto auto;
                margin: 0px;
                width: 250px;
                flex-direction: column;
              "
            >
              <h6
                class="mb-2 text-sm font-normal text-slate-500 dark:text-zink-300"
              >
                Welcome to SK Overseas
              </h6>
              <a href="javascript:void(0);" class="flex gap-3 mb-3">
                <div class="relative inline-block shrink-0">
                  <div class="rounded bg-slate-100 dark:bg-zink-500">
                    <img
                      src="assets/images/users/user-profile.png"
                      alt=""
                      class="size-12 rounded"
                    />
                  </div>
                  <span
                    class="-top-1 ltr:-right-1 rtl:-left-1 absolute size-2.5 bg-green-400 border-2 border-white rounded-full dark:border-zink-600"
                  ></span>
                </div>
                <div>
                  <h6 class="mb-1 text-15">{{ userName | async }}</h6>
                  <p class="text-slate-500 dark:text-zink-300">
                    {{ userType | async }}
                  </p>
                </div>
              </a>
              <ul>
                <li>
                  <a
                    class="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:focus:text-custom-500"
                    (click)="profile()"
                    ><lucide-angular
                      name="user"
                      [class]="'inline-block size-4 ltr:mr-2 rtl:ml-2'"
                    ></lucide-angular>
                    Profile</a
                  >
                </li>
                <!-- <li>
                  <a class="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:focus:text-custom-500"
                    routerLink="/apps-mailbox"><lucide-angular name="mail"
                      [class]="'inline-block size-4 ltr:mr-2 rtl:ml-2'"></lucide-angular>
                    Inbox
                    <span
                      class="inline-flex items-center justify-center size-5 ltr:ml-2 rtl:mr-2 text-[11px] font-medium border rounded-full text-white bg-red-500 border-red-500">15</span></a>
                </li>
                <li>
                  <a class="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:focus:text-custom-500"
                    routerLink="/apps-chat"><lucide-angular name="messages-square"
                      [class]="'inline-block size-4 ltr:mr-2 rtl:ml-2'"></lucide-angular>
                    Chat</a>
                </li>
                <li>
                  <a class="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:focus:text-custom-500"
                    routerLink="/pages-pricing"><lucide-angular name="gem"
                      [class]="'inline-block size-4 ltr:mr-2 rtl:ml-2'"></lucide-angular>
                    Upgrade
                    <span
                      class="inline-flex items-center justify-center w-auto h-5 ltr:ml-2 rtl:mr-2 px-1 text-[12px] font-medium border rounded text-white bg-sky-500 border-sky-500">Pro</span></a>
                </li> -->
                <li
                  class="pt-2 mt-2 border-t border-slate-200 dark:border-zink-500"
                >
                  <a
                    class="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:focus:text-custom-500"
                    (click)="logout()"
                    ><lucide-angular
                      name="log-out"
                      [class]="'inline-block size-4 ltr:mr-2 rtl:ml-2'"
                    ></lucide-angular>
                    Sign Out</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="relative">
    <div
      class="absolute top-2 right-72 me-3"
      aria-live="polite"
      toastContainer
    ></div>
  </div>
</header>

<drawer id="cartSidePenal" [position]="'drawer-end'">
  <div
    class="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
  >
    <div class="grow">
      <h5 class="mb-0 text-16">
        Shopping Cart
        <span
          class="inline-flex items-center justify-center size-5 ml-1 text-[11px] font-medium border rounded-full text-white bg-custom-500 border-custom-500"
          >3</span
        >
      </h5>
    </div>
    <div class="shrink-0">
      <button
        dismissDrawer
        [DrawerDismiss]="'cartSidePenal'"
        class="transition-all duration-150 ease-linear text-slate-500 hover:text-slate-800"
      >
        <lucide-angular name="x" [class]="'size-4'"></lucide-angular>
      </button>
    </div>
  </div>
  <div
    class="px-4 py-3 text-sm text-green-500 border border-transparent bg-green-50 dark:bg-green-400/20"
  >
    <span class="font-bold underline">TAILWICK50</span> Coupon code applied
    successfully.
  </div>
  <div>
    <div class="h-[calc(100vh_-_370px)] p-4 overflow-y-auto product-list">
      <div class="flex flex-col gap-4">
        @for(data of cartlist;track $index){
        <div class="flex gap-2 product">
          <div
            class="flex items-center justify-center size-12 rounded-md bg-slate-100 shrink-0 dark:bg-zink-500"
          >
            <img src="{{ data.image }}" alt="" class="h-8" />
          </div>
          <div class="overflow-hidden grow">
            <div class="ltr:float-right rtl:float-left">
              <button
                class="transition-all duration-150 ease-linear text-slate-500 dark:text-zink-200 hover:text-red-500 dark:hover:text-red-500"
              >
                <lucide-angular name="x" [class]="'size-4'"></lucide-angular>
              </button>
            </div>
            <a
              href="javascript:void(0);"
              class="transition-all duration-200 ease-linear hover:text-custom-500"
            >
              <h6 class="mb-1 text-15">{{ data.name }}</h6>
            </a>
            <div class="flex items-center mb-3">
              <h5 class="text-base product-price">
                $<span>
                  {{ data.price }}
                </span>
              </h5>
              <div
                class="font-normal rtl:mr-1 ltr:ml-1 text-slate-500 dark:text-zink-200"
              >
                ({{ data.category }})
              </div>
            </div>
            <div class="flex items-center justify-between gap-3">
              <div class="inline-flex text-center input-step">
                <button
                  type="button"
                  (click)="calculateQty('0', data.quantity, $index)"
                  class="border size-9 leading-[15px] minus bg-white dark:bg-zink-700 dark:border-zink-500 ltr:rounded-l rtl:rounded-r transition-all duration-200 ease-linear border-slate-200 text-slate-500 dark:text-zink-200 hover:bg-custom-500 dark:hover:bg-custom-500 hover:text-custom-50 dark:hover:text-custom-50 hover:border-custom-500 dark:hover:border-custom-500 focus:bg-custom-500 dark:focus:bg-custom-500 focus:border-custom-500 dark:focus:border-custom-500 focus:text-custom-50 dark:focus:text-custom-50"
                >
                  <lucide-angular
                    name="minus"
                    [class]="'inline-block size-4'"
                  ></lucide-angular>
                </button>
                <input
                  type="number"
                  class="w-12 text-center h-9 border-y product-quantity dark:bg-zink-700 focus:shadow-none dark:border-zink-500"
                  value="{{ data.quantity }}"
                  min="0"
                  max="100"
                  readonly
                />

                <button
                  (click)="calculateQty('1', data.quantity, $index)"
                  type=" button"
                  class="transition-all duration-200 ease-linear bg-white border dark:bg-zink-700 dark:border-zink-500 ltr:rounded-r rtl:rounded-l size-9 border-slate-200 plus text-slate-500 dark:text-zink-200 hover:bg-custom-500 dark:hover:bg-custom-500 hover:text-custom-50 dark:hover:text-custom-50 hover:border-custom-500 dark:hover:border-custom-500 focus:bg-custom-500 dark:focus:bg-custom-500 focus:border-custom-500 dark:focus:border-custom-500 focus:text-custom-50 dark:focus:text-custom-50"
                >
                  <lucide-angular
                    name="plus"
                    [class]="'inline-block size-4'"
                  ></lucide-angular>
                </button>
              </div>
              <h6 class="product-line-price">{{ data.total }}</h6>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
    <div class="p-4 border-t border-slate-200 dark:border-zink-500">
      <table class="w-full mb-3">
        <tbody class="table-total">
          <tr>
            <td class="py-2">Sub Total :</td>
            <td class="text-right cart-subtotal">${{ subtotal }}</td>
          </tr>
          <tr>
            <td class="py-2">
              Discount <span class="text-muted">(TAILWICK50)</span>:
            </td>
            <td class="text-right cart-discount">-${{ discount }}</td>
          </tr>
          <tr>
            <td class="py-2">Shipping Charge :</td>
            <td class="text-right cart-shipping">${{ shippingRate }}</td>
          </tr>
          <tr>
            <td class="py-2">Estimated Tax (12.5%) :</td>
            <td class="text-right cart-tax">${{ tax }}</td>
          </tr>
          <tr class="font-semibold">
            <td class="py-2">Total :</td>
            <td class="text-right cart-total">${{ totalprice }}</td>
          </tr>
        </tbody>
      </table>
      <div class="flex items-center justify-between gap-3">
        <a
          dismissDrawer
          [DrawerDismiss]="'cartSidePenal'"
          routerLink="/product-grid"
          class="w-full text-white btn bg-slate-500 border-slate-500 hover:text-white hover:bg-slate-600 hover:border-slate-600 focus:text-white focus:bg-slate-600 focus:border-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:border-slate-600 active:ring active:ring-slate-100 dark:ring-slate-400/10"
          >Continue Shopping</a
        >
        <a
          dismissDrawer
          [DrawerDismiss]="'cartSidePenal'"
          routerLink="/ecommerce-checkout"
          class="w-full text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-custom-400/20"
          >Checkout</a
        >
      </div>
    </div>
  </div>
</drawer>
