import { Component } from '@angular/core';

@Component({
  selector: 'app-verify-email-basic',
  standalone: true,
  imports: [],
  templateUrl: './verify-email-basic.component.html',
  styles: ``
})
export class VerifyEmailBasicComponent {

}
