import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-verify-email-cover',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './verify-email-cover.component.html',
  styles: ``
})
export class VerifyEmailCoverComponent {

}
